<template>
  <a-spin tip="Cargando información..." :spinning="loader"></a-spin>

  <section class="p-6">
    <LayoutPublicHeader :isDark="true" />
    <PageHeader title="GESTANTES CON ANEMIA SEGÚN DISTRITO" goBack class="my-3" />

    <div class="flex flex-wrap mb-4">
      <fieldset
        class="border border-solid w-full lg:w-1/2 border-gray-300 flex justify-center items-center px-8"
      >
        <legend class="text-sm text-center m-0"></legend>
        <MapaFiltro
          :periodos="periodos"
          @fetchEstablecimientos="fetchEstablecimientos"
          @getAllByProvincia="getAllByProvincia"
        />
      </fieldset>
    </div>

    <div class="w-full flex flex-wrap lg:flex-nowrap gap-4">
      <div class="w-full lg:w-1/2">
        <div class="overflow-x-auto w-full mt-2">
          <a-card size="small" class="relative">
            <template #title>
              <div class="text-center flex justify-between items-center px-3 py-1">
                <p class="font-semibold">DATOS POR DISTRITO ({{ establecimientos.length }})</p>
                <div class="flex gap-3">
                  <a-tooltip>
                    <template #title>Traer de nuevo todos los datos</template>
                    <a-button shape="circle" @click="resetAllFetch">
                      <template #icon><ReloadOutlined /></template>
                    </a-button>
                  </a-tooltip>

                  <a-tooltip>
                    <template #title>Exportar en pdf</template>
                    <a-button
                      shape="circle"
                      v-print="printObj"
                      class="text-red-500 hover:text-red-300"
                    >
                      <template #icon><FilePdfFilled /></template>
                    </a-button>
                  </a-tooltip>

                  <a-tooltip>
                    <template #title>Exportar en excel</template>
                    <a-button
                      shape="circle"
                      @click="handleDowload"
                      class="text-green-500 hover:text-green-300"
                    >
                      <template #icon><FileExcelFilled /></template>
                    </a-button>
                  </a-tooltip>
                </div>
              </div>
            </template>

            <div class="overflow-x-auto w-full">
              <a-table
                size="small"
                rowKey="establecimiento"
                :columns="columns"
                :data-source="establecimientos"
                :custom-row="customRow"
                :pagination="true"
                :loading="isLoading"
                :rowClassName="
                  (record, index) =>
                    index % 2 === 0 ? 'cursor-pointer bg-white' : 'cursor-pointer bg-gray-100'
                "
              >
                <template #rangepages="{ record }">
                  <p class="text-center">{{ establecimientos.indexOf(record) + 1 }}</p>
                </template>

                <template #porcentaje="{ record, text: porcentaje }">
                  <div class="flex gap-4 justify-between items-center">
                    <div
                      class="w-3 h-3 rounded-full"
                      :style="{ background: record.porcentajeHex }"
                    ></div>
                    <p class="font-bold">{{ porcentaje }} % &nbsp;</p>
                    <p class="text-xs text-gray-400" style="width: 80px">
                      {{ record.total_numerador_establecimiento }} /
                      {{ record.total_denominador_establecimiento }}
                    </p>
                  </div>
                </template>
              </a-table>
            </div>
            <div class="absolute bottom-5 right-8">
              <p>Total de casos: {{ total_numerador_acumulado }} (Consolidado)</p>
              <!-- <p class="text-gray-400 italic right-8">CONSOLIDADO</p> -->
            </div>
          </a-card>
        </div>
      </div>

      <div class="w-full lg:w-1/2 lg:-mt-28">
        <a-card class="w-full">
          <template #title>
            <p class="text-center font-bold">
              MAPA DE CALOR DE GESTANTES CON DIAGNÓSTICO DE ANEMIA
            </p>
          </template>
          <div id="map" />

          <h4 class="text-base my-2">Leyenda:</h4>
          <div v-for="escala in escalaColores" :key="escala.texto" class="flex gap-2 items-center">
            <div
              class="w-4 h-4 rounded-full border border-white my-5 md:my-0"
              :style="{ backgroundColor: escala.color }"
            ></div>
            {{ escala.texto }}
          </div>
        </a-card>
      </div>
    </div>

    <p class="text-gray-400 my-4">Fuente: {{ fechaIngresoTabla }}</p>

    <PrintMapaGestante :establecimientos="establecimientos" />
  </section>
</template>

<script>
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { ref, onMounted, onUnmounted, toRaw, reactive } from "vue";
import {
  calculaPorcentaje,
  semaforoMapa,
  semaforoMapaInverso,
  generaLeyendaSemaforo,
  generaLeyendaSemaforoInverso
} from "@/utils";
import ApiIndicador from "@/api/indicador";
import MapaApi from "@/api/mapa";
import { useStore } from "vuex";
import moment from "moment";
import { columns } from "./utilsMapa";
import { ReloadOutlined, FilePdfFilled, FileExcelFilled } from "@ant-design/icons-vue";
import PrintMapaGestante from "@/print/PrintMapaGestante.vue";
import MapaFiltro from "./MapaFiltro.vue";
import LayoutPublicHeader from "@/layout/LayoutPublic/LayoutPublicHeader.vue";
import PageHeader from "@/components/PageHeader.vue";

export default {
  components: {
    MapaFiltro,
    PrintMapaGestante,
    PageHeader,
    LayoutPublicHeader,
    // icons
    ReloadOutlined,
    FileExcelFilled,
    FilePdfFilled
  },
  setup() {
    const store = useStore();
    const establecimientos = ref([]);
    const fechaIngresoTabla = ref("");
    const periodos = ref([]);
    const escalaColores = ref([]);
    const isLoading = ref(false);
    const total_numerador_acumulado = ref(0);
    const isUmbralInverso = ref(true);
    const loader = ref(true);

    const coloresSemaforo = reactive({
      POCO_DENOMINADOR: "5",
      PORCENTAJE_BAJO: "10",
      PORCENTAJE_MEDIO: "20",
      PORCENTAJE_ALTO: "30"
    });

    const coloresSemaforoMapa = reactive({
      RANGO_SINDATO: "5",
      RANGO_BAJO: "10",
      RANGO_MEDIO: "20",
      RANGO_ALTO: "30"
    });

    const idindicador = 53; //  ind_registro_fin2

    const printObj = reactive({
      id: "printMapaCalor",
      popTitle: "SIVINUR | NUTRICIONAL TABULAR"
    });

    const mapa = ref({
      abreviatura: "",
      idcoordenada: "",
      idubigeo: "",
      provincias: [],
      denominador: [],
      data_nombres: [],
      denominacion: "",
      lat: "-70.2646366",
      lng: "-17.651106",
      zoom: 7.8
    });

    const customRow = (record) => {
      return {
        onClick: () => {
          mapa.value = {
            abreviatura: "",
            idcoordenada: "",
            idubigeo: "1",
            denominador: record.total_denominador_establecimiento,
            numeradores: record.total_numerador_establecimiento,
            denominacion: record.establecimiento,
            microred: record.microred,
            lat: record.longitud,
            lng: record.latitud,
            zoom: 16
          };

          generateMap();
        }
      };
    };

    const fetchEstablecimientos = () => {
      isLoading.value = true;
      const provincia = store.getters["mapa/provincia"];
      const distrito = store.getters["mapa/distrito"];
      const periodo = store.getters["mapa/periodo"];

      var fecha_corte = store.getters["mapa/fecha_corte"];

      if (fecha_corte == "") fecha_corte = moment().format("YYYY-MM-DD");

      ApiIndicador.getIndicador_estadisticaFechaCorte(
        distrito,
        periodo,
        provincia,
        "sivi_data.ind_registro_mc2",
        fecha_corte
      )
        .then((response) => {
          total_numerador_acumulado.value = 0;
          const conPorcentajes = response.data.map((p) => {
            let porcentajeHex = "";

            const porcentaje = calculaPorcentaje(
              p.total_numerador_establecimiento,
              p.total_denominador_establecimiento
            );

            if (isUmbralInverso.value) {
              porcentajeHex = semaforoMapaInverso(
                toRaw(coloresSemaforoMapa),
                p.total_denominador_establecimiento,
                porcentaje
              );
            } else {
              porcentajeHex = semaforoMapa(
                toRaw(coloresSemaforoMapa),
                p.total_denominador_establecimiento,
                porcentaje
              );
            }

            total_numerador_acumulado.value =
              Number(total_numerador_acumulado.value) + Number(p.total_numerador_establecimiento);

            return {
              ...p,
              porcentaje,
              porcentajeHex
            };
          });

          establecimientos.value = conPorcentajes;
        })
        .catch((err) => console.log(err))
        .finally(() => {
          isLoading.value = false;
        });
    };

    const fetchPeriodos = () => {
      ApiIndicador.getperiodoByIndicador("ind_registro_fin2")
        .then((response) => {
          periodos.value = response.data;
        })
        .catch((err) => console.log(err));
    };

    // --------------- GENERA MAPA---------------
    const generateMap = async () => {
      mapboxgl.accessToken = process.env.VUE_APP_MAPBOXTOKEN;
      var mapaAcceso = toRaw(mapa.value);

      const map = new mapboxgl.Map({
        container: "map",
        center: [mapaAcceso.lat, mapaAcceso.lng],
        zoom: mapaAcceso.zoom,
        style: "mapbox://styles/mapbox/light-v9"
      });

      if (mapaAcceso.idubigeo == "") {
        if (mapaAcceso.data_nombres.length == 1) {
          const provincia = store.getters["mapa/provincia"];
          const distrito = store.getters["mapa/distrito"];
          const periodo = store.getters["mapa/periodo"];

          var fecha_corte = store.getters["mapa/fecha_corte"];

          if (fecha_corte == "") fecha_corte = moment().format("YYYY-MM-DD");

          ApiIndicador.getIndicador_estadisticaFechaCorte(
            distrito,
            periodo,
            provincia,
            "sivi_data.ind_registro_mc2",
            fecha_corte
          )
            .then((response) => {
              var distritos = response.data;

              distritos.forEach((element) => {
                let colorGenerated = "";

                const calcular = calculaPorcentaje(
                  element.total_numerador_establecimiento,
                  element.total_denominador_establecimiento
                );

                if (isUmbralInverso.value) {
                  colorGenerated = semaforoMapaInverso(
                    toRaw(coloresSemaforoMapa),
                    element.total_numerador_establecimiento,
                    calcular
                  );
                } else {
                  colorGenerated = semaforoMapa(
                    toRaw(coloresSemaforoMapa),
                    element.total_numerador_establecimiento,
                    calcular
                  );
                }

                map.addSource(element.distrito, {
                  type: "geojson",
                  data: process.env.VUE_APP_BASE_API + "/api/PruebaName/V1/" + element.distrito
                });

                map.addLayer({
                  id: element.distrito,
                  type: "fill",
                  source: element.distrito,
                  paint: {
                    "fill-color": colorGenerated,
                    "fill-opacity": 0.8,
                    "fill-outline-color": "rgba(20, 100, 25, 1)"
                  }
                });

                map.on("click", element.distrito, function (e) {
                  new mapboxgl.Popup()
                    .setLngLat(e.lngLat)
                    .setHTML(
                      `<h2 style="color:Teal;font-weight:bold">${element.distrito}</h2><p>Porcentaje: <strong>${calcular} %</strong></p><p>Numerador: <strong>${element.total_numerador_establecimiento}</strong></p><p>Denominador: <strong>${element.total_denominador_establecimiento}</strong></p>`
                    )
                    .addTo(map);
                });
              });
            })
            .catch((err) => console.log(err));
        } else {
          map.on("load", () => {
            map.addSource("CANDARAVE", {
              type: "geojson",
              data: process.env.VUE_APP_BASE_API + "/api/Prueba/V1/candarave"
            });

            map.addSource("TACNA", {
              type: "geojson",
              data: process.env.VUE_APP_BASE_API + "/api/Prueba/V1/tacna"
            });

            map.addSource("JORGE BASADRE", {
              type: "geojson",
              data: process.env.VUE_APP_BASE_API + "/api/Prueba/V1/jorge_basadre"
            });

            map.addSource("TARATA", {
              type: "geojson",
              data: process.env.VUE_APP_BASE_API + "/api/Prueba/V1/tarata"
            });

            for (let index = 0; index < mapaAcceso.provincias.length; index++) {
              let colorGenerated = "";

              if (isUmbralInverso.value) {
                colorGenerated = semaforoMapaInverso(
                  toRaw(coloresSemaforoMapa),
                  mapaAcceso.denominador[index],
                  mapaAcceso.provincias[index]
                );
              } else {
                colorGenerated = semaforoMapa(
                  toRaw(coloresSemaforoMapa),
                  mapaAcceso.denominador[index],
                  mapaAcceso.provincias[index]
                );
              }

              map.addLayer({
                id: mapaAcceso.data_nombres[index],
                type: "fill",
                source: mapaAcceso.data_nombres[index],
                paint: {
                  "fill-color": colorGenerated,
                  "fill-opacity": 0.8,
                  "fill-outline-color": "rgba(20, 100, 25, 1)"
                }
              });

              map.on("click", mapaAcceso.data_nombres[index], function (e) {
                new mapboxgl.Popup()
                  .setLngLat(e.lngLat)
                  .setHTML(
                    `<h2 style="color:Teal;font-weight:bold">${mapaAcceso.data_nombres[index]}</h2><p>Porcentaje: <strong>${mapaAcceso.provincias[index]} %</strong></p><p>Numerador: <strong>${mapaAcceso.numeradores[index]}</strong></p><p>Denominador: <strong>${mapaAcceso.denominador[index]}</strong></p>`
                  )
                  .addTo(map);
              });
            }
          });
        }
      } else if (mapaAcceso.idubigeo == "1") {
        map.on("load", () => {
          const calcular = calculaPorcentaje(mapaAcceso.numeradores, mapaAcceso.denominador);
          const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
            `<h2 style="color:Teal;font-weight:bold">${mapaAcceso.denominacion}</h2><p>Porcentaje: <strong>${calcular} %</strong></p><p>Numerador: <strong>${mapaAcceso.numeradores}</strong></p><p>Denominador: <strong>${mapaAcceso.denominador}</strong></p>`
          );
          new mapboxgl.Marker()
            .setLngLat([mapaAcceso.lat, mapaAcceso.lng])
            .setPopup(popup)
            .addTo(map);
        });
      } else {
        map.on("load", () => {
          map.addSource("distrito", {
            type: "geojson",
            data: process.env.VUE_APP_BASE_API + "/api/PruebaName/V1/" + mapaAcceso.idubigeo
          });

          var info = establecimientos.value;

          if (info.length == 0) return;

          if (info.length > 0) {
            var denominaor = info[0].total_denominador_establecimiento;
            var numerador = info[0].total_numerador_establecimiento;
            var porcentaje = calculaPorcentaje(numerador, denominaor);
            let colorGenerated = "";

            if (isUmbralInverso.value) {
              colorGenerated = semaforoMapaInverso(
                toRaw(coloresSemaforoMapa),
                denominaor,
                porcentaje
              );
            } else {
              colorGenerated = semaforoMapa(toRaw(coloresSemaforoMapa), denominaor, porcentaje);
            }

            map.addLayer({
              id: "distrito-fill",
              type: "fill",
              source: "distrito",
              paint: {
                "fill-color": colorGenerated,
                "fill-opacity": 0.8,
                "fill-outline-color": "rgba(20, 100, 25, 1)"
              }
            });

            map.on("click", "distrito-fill", function (e) {
              new mapboxgl.Popup()
                .setLngLat(e.lngLat)
                .setHTML(
                  `<h2 style="color:Teal;font-weight:bold">${mapaAcceso.idubigeo}</h2><p>Porcentaje: <strong> ${porcentaje} %</strong></p><p>Numerador: <strong> ${numerador} </strong></p><p>Denominador: ${denominaor} <strong> </strong></p>`
                )
                .addTo(map);
            });
          } else {
            map.addLayer({
              id: "distrito-fill",
              type: "fill",
              source: "distrito",
              paint: {
                "fill-color": "red",
                "fill-opacity": 0.8,
                "fill-outline-color": "rgba(20, 100, 25, 1)"
              }
            });
          }
        });
      }

      loader.value = false;
    };

    const getAllByProvincia = () => {
      const provincia = store.getters["mapa/provincia"];
      var fecha_corte = store.getters["mapa/fecha_corte"];

      if (fecha_corte == "") fecha_corte = moment().format("YYYY");

      ApiIndicador.getAllByProvinciaIdPeriodoFechaCorte(
        "ind_registro_fin2",
        provincia,
        "",
        fecha_corte
      )
        .then((response) => {
          console.log(response);

          var denominadores = [];
          var numeradores = [];
          var nombres = [];
          var data = [];

          response.data.forEach((element) => {
            denominadores.push(element.total_denominador_provincia);
            numeradores.push(element.total_numerador_provincia);
            nombres.push(element.provincia);

            data.push(
              calculaPorcentaje(
                element.total_numerador_provincia,
                element.total_denominador_provincia
              )
            );
          });

          var distrito = store.getters["mapa/distrito"];

          mapa.value = {
            abreviatura: "",
            idcoordenada: "",
            idubigeo: distrito,
            provincias: data,
            denominador: denominadores,
            numeradores: numeradores,
            data_nombres: nombres,
            denominacion: "",
            lat: "-70.2646366",
            lng: "-17.651106",
            zoom: 7.8
          };

          generateMap();
        })
        .catch((err) => console.log(err));
    };

    const handleDowload = () => {
      const provincia = store.getters["mapa/provincia"];
      const distrito = store.getters["mapa/distrito"];
      const idperiodo = store.getters["mapa/periodo"];
      var fecha_corte = store.getters["mapa/fecha_corte"];

      if (fecha_corte == "") fecha_corte = moment().format("YYYY-MM-DD");

      ApiIndicador.getReporteIndicador02(
        distrito,
        idperiodo,
        provincia,
        "mapa_reporte",
        "MAPA DE CALOR DE GESTANTES CON DIAGNÓSTICO DE ANEMIA",
        fechaIngresoTabla.value,
        fecha_corte
      )
        .then((response) => {
          window.open(
            process.env.VUE_APP_BASE_API + "/CSV/" + response.data["nombre_Archivo"],
            "_blank"
          );
        })
        .catch((err) => console.log(err));
    };

    const getEscalaColoresMapa = async () => {
      var res = await MapaApi.getSemaforoMapaPublicv2(idindicador);

      coloresSemaforo.RANGO_SINDATO = res.data.find((p) => p.clave === "RANGO_SINDATO").descripcion;
      coloresSemaforo.RANGO_BAJO = res.data.find((p) => p.clave === "RANGO_BAJO").descripcion;
      coloresSemaforo.RANGO_MEDIO = res.data.find((p) => p.clave === "RANGO_MEDIO").descripcion;
      coloresSemaforo.RANGO_ALTO = res.data.find((p) => p.clave === "RANGO_ALTO").descripcion;

      coloresSemaforoMapa.RANGO_SINDATO = res.data.find((p) => p.clave === "RANGO_SINDATO").valor;
      coloresSemaforoMapa.RANGO_BAJO = res.data.find((p) => p.clave === "RANGO_BAJO").valor;
      coloresSemaforoMapa.RANGO_MEDIO = res.data.find((p) => p.clave === "RANGO_MEDIO").valor;
      coloresSemaforoMapa.RANGO_ALTO = res.data.find((p) => p.clave === "RANGO_ALTO").valor;

      if (isUmbralInverso.value) {
        escalaColores.value = generaLeyendaSemaforoInverso(toRaw(coloresSemaforo));
      } else {
        escalaColores.value = generaLeyendaSemaforo(toRaw(coloresSemaforo));
      }
    };

    const fetchParametroIndicadorByClave = () => {
      ApiIndicador.getParametroIndicadorByClave(idindicador, "UMBRAL_INVERTIDO")
        .then((res) => {
          const parametro = res.data[0];

          if (parametro && parametro?.valor == "1") {
            isUmbralInverso.value = true;
          } else {
            isUmbralInverso.value = false;
          }
        })
        .catch(() => {
          isUmbralInverso.value = false;
        })
        .finally(() => {
          getEscalaColoresMapa();
          fetchEstablecimientos();
        });
    };

    onMounted(async () => {
      store.dispatch("mapa/cleanFilters");

      periodos.value = await ApiIndicador.getperiodoByIndicador("ind_registro_fin2");

      fetchPeriodos();
      getAllByProvincia();
      fetchParametroIndicadorByClave();

      fetchFooterFuente();
    });

    onUnmounted(() => {
      store.dispatch("mapa/cleanFilters");
    });

    const resetAllFetch = () => {
      store.dispatch("mapa/cleanFilters");
      fetchEstablecimientos();
    };

    const fetchFooterFuente = () => {
      ApiIndicador.getFooterFuente(53)
        .then((res) => {
          fechaIngresoTabla.value = res.data[0]?.LABEL_FUENTE || "";
        })
        .catch((err) => {
          console.log(err);
        });
    };

    return {
      // establecimientos,
      customRow,
      periodos,
      getAllByProvincia,
      calculaPorcentaje,
      establecimientos,
      handleDowload,
      columns,
      escalaColores,
      coloresSemaforo,
      fechaIngresoTabla,
      printObj,
      resetAllFetch,
      isLoading,
      fetchEstablecimientos,
      total_numerador_acumulado,
      loader
    };
  }
};
</script>

<style>
#map {
  height: 80vh;
}

#map2 {
  height: 80vh;
}

.mapaMargin {
  margin-top: -120px;
}

.bold {
  font-weight: bold;
}
</style>
